/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Space } from 'antd';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { filterGamesThunk } from 'redux/reducers/casino/games/games.thunks';
import { RootState } from 'redux/store.types';
import CheckboxList from 'components/Common/Checkbox/CheckboxList';

import { selecetdDataConfig } from '../Aggregators/aggregatorConfig/aggregatorGamesTable.config';

import style from './components/Games.module.scss';
import {
  checkboxGroups,
  deviecSupportConfigs,
  generalGamesTableConfig,
} from './gamesConfig/generalGamesTable.config';
import GeneralGamesTable from './components/GeneralGameTable';
import { fetchProjectList } from 'redux/reducers/projects/project.thunks';

const Games: React.FC = () => {
  const dispatch = useAppDispatch();
  const { allGames } = useSelector((state: RootState) => state.games);

  const initialCheckedList = {
    visibility: [],
    siteSection: [],
    type: [],
    freeSpins: [],
    deviceSupport: [],
    hasDemo: [],
  };

  const [checkedList, setCheckedList] = useState(initialCheckedList);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchProjectList());
  }, []);

  const handleSearch = () => {
    setCurrentPage(1);
    dispatch(
      filterGamesThunk({
        body: {
          visibility: checkedList.visibility,
          type: checkedList.type,
          hasFreespins: checkedList.freeSpins,
          hasDemo: checkedList.hasDemo,
          siteSection: (checkedList.siteSection as string[]).map(
            item => item.charAt(0).toLowerCase() + item.slice(1),
          ),
          deviceSupport: Object.entries(deviecSupportConfigs)
            .filter(
              ([key, value]) =>
                Array.isArray(checkedList.deviceSupport) &&
                checkedList.deviceSupport.some(item => item === value),
            )
            .map(([key]) => key),
        },
        additionalProps: currentPage,
      }),
    );
    setIsFilterOpen(false);
  };

  useEffect(() => {
    dispatch(
      filterGamesThunk({
        body: {
          visibility: checkedList.visibility,
          type: checkedList.type,
          hasFreespins: checkedList.freeSpins,
          hasDemo: checkedList.hasDemo,
          siteSection: (checkedList.siteSection as string[]).map(
            item => item.charAt(0).toLowerCase() + item.slice(1),
          ),
          deviceSupport: Object.entries(deviecSupportConfigs)
            .filter(
              ([key, value]) =>
                Array.isArray(checkedList.deviceSupport) &&
                checkedList.deviceSupport.some(item => item === value),
            )
            .map(([key]) => key),
        },
        additionalProps: { currentPage },
      }),
    );
  }, []);

  const handleCheckboxChange = (updatedCheckedList: any) => {
    setCheckedList(updatedCheckedList);
    console.log(checkedList);
  };

  const handleReset = () => {
    setCheckedList(initialCheckedList);
  };

  return (
    <>
      <Space>
        <Dropdown
          overlay={
            <div className={style.dropdownContent}>
              <CheckboxList
                groups={checkboxGroups}
                onChange={handleCheckboxChange}
                checkedList={checkedList}
              />
              <Button
                className={style.filterSerchBtn}
                onClick={handleSearch}
                type='primary'>
                Filter
              </Button>

              <Button
                className={style.resetBtn}
                onClick={handleReset}
                type='primary'>
                Reset
              </Button>
            </div>
          }
          trigger={['click']}
          placement='bottomLeft'
          open={isFilterOpen}
          onOpenChange={visible => setIsFilterOpen(visible)}>
          {isFilterOpen ? (
            <FilterOutlined className={style.filterOutlinedIcon} />
          ) : (
            <FilterFilled className={style.filterFilledIcon} />
          )}
        </Dropdown>
      </Space>

      <GeneralGamesTable
        checkedList={checkedList}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        selecetdDataConfig={selecetdDataConfig}
        generalGamesTableConfig={generalGamesTableConfig}
      />
    </>
  );
};

export default Games;
