/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateGame } from './games.slice';

function transformKeysToBoolean(obj: any) {
  for (const key in obj) {
    if (key === 'hasDemo') {
      obj[key] = obj[key] === 'Yes';
    } else if (key === 'hasFreespins') {
      obj[key] = obj[key] === 'Yes';
    } else if (key === 'visibility') {
      obj[key] = obj[key] === 'Visible';
    }
  }

  return obj;
}

function processKeys(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        if (
          obj[key].length === 2 &&
          key !== 'siteSection' &&
          key !== 'deviceSupport' &&
          key !== 'type' &&
          key !== 'name'
        ) {
          delete obj[key];
        } else if (
          obj[key].length === 1 &&
          key !== 'siteSection' &&
          key !== 'deviceSupport' &&
          key !== 'type' &&
          key !== 'name'
        ) {
          obj[key] = obj[key][0];
        } else if (obj[key].length === 0) {
          delete obj[key];
        }
      } else {
        processKeys(obj[key]);
      }
    } else if (obj[key] === '') {
      delete obj[key];
    }
  }

  return transformKeysToBoolean(obj);
}

export const updateCurrentGameThunks = createAsyncThunk(
  'games/updateCurrentGameThunks',
  async (
    { editingGame, generalInfo }: { editingGame: any; generalInfo: any },
    thunkAPI,
  ) => {
    try {
      const formDataToUpload = new FormData();
      if (editingGame.image || editingGame.image === '') {
        const response = await fetch(editingGame.image);
        const blob = await response.blob();
        const file =
          editingGame.image === ''
            ? ' '
            : new File([blob], 'image.jpg', { type: blob.type });
        formDataToUpload.append('image', file);
      }

      Object.keys(editingGame).forEach(key => {
        const value = editingGame[key];
        if (key !== 'image') {
          formDataToUpload.append(key, value);
        }
      });
      const response = await axios.put(
        `casino/games/${generalInfo.provider}/${generalInfo.uuid}/${generalInfo.id}`,
        formDataToUpload,
      );
      console.log(generalInfo);
      thunkAPI.dispatch(updateGame({ id: generalInfo.id, ...editingGame }));
      return response.data;
    } catch (error: any) {
      console.error('Error:', error);
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchGameById = createAsyncThunk(
  'games/fetchGameById',
  async (gameId: any) => {
    try {
      const response = await axios.get(`/casino/games/${gameId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const searchGamesThunk = createAsyncThunk(
  'games/searchGamesThunk',
  async (
    {
      keyword,
      siteSection,
      mobile,
    }: { keyword: string; siteSection: string; mobile: boolean },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post('/casino/games/search', {
        keyword,
        siteSection,
        mobile,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const filterGamesThunk = createAsyncThunk(
  'games/filterGames',
  async (
    { body, additionalProps = {} }: { body: any; additionalProps?: any },
    { rejectWithValue },
  ) => {
    let filters = processKeys(body);

    const defaultProps = {
      sort: 'priority',
      pageSize: 20,
      currentPage: 1,
      order: 'asc',
    };

    const props = { ...defaultProps, ...additionalProps };

    const queryParams = [
      `sort=${props.sort}`,
      `limit=${props.pageSize}`,
      `page=${props.currentPage}`,
      `order=${props.order}`,
    ].join('&');

    try {
      const response = await axios.post(
        `/casino/games/filtered?${queryParams}`,
        { filters },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to filter games',
      );
    }
  },
);
