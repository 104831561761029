/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagData, TagsState } from './types';
import {
  createTagThunk,
  searchGamesThunk,
  fetchTags,
  updateTagThunk,
  getTagGamesThunk,
} from './tags.thunks';

const initialState: TagsState = {
  count: 0,
  tags: [],
  loading: false,
  error: null,
  selectedTag: null,
  searchedGames: [],
  loadingForModal: false,
  selectedFullTagInfo: null,
  gameCount: 0,
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setSelectedTag: (state, action: PayloadAction<TagData | null>) => {
      state.selectedTag = action.payload;
    },
    updateTag: (state, action: PayloadAction<any>) => {
      const index = state.tags.findIndex(tag => tag.id === action.payload.id);
      if (index !== -1) {
        state.tags[index] = action.payload;
      }
    },
    addTag: (state, action: PayloadAction<TagData>) => {
      state.tags.push(action.payload);
    },
    clearSearchGames: state => {
      state.searchedGames = [];
    },
    removeGameFromTag: (state, action: PayloadAction<string>) => {
      if (state.selectedFullTagInfo) {
        state.selectedFullTagInfo = state.selectedFullTagInfo.filter(
          (game: any) => game.id !== action.payload,
        );
      }
    },
    removeTag: (state, action: PayloadAction<string>) => {
      if (state.tags) {
        state.tags = state.tags.filter((tag: any) => tag.id !== action.payload);
      }
    },
    clearSelctedFullTgaInfo: state => {
      state.selectedFullTagInfo = [];
    },

    clearFilters(state) {
      state.selectedFullTagInfo = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTags.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload.tagsList;
        state.count = action.payload.count;
      })

      .addCase(fetchTags.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload?.message || 'Failed to fetch tags';
      })
      .addCase(createTagThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTagThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.tags.unshift(action.payload.tagList[0]);
      })
      .addCase(createTagThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create tag';
      })
      .addCase(searchGamesThunk.pending, state => {
        state.loadingForModal = true;
        state.error = null;
      })
      .addCase(searchGamesThunk.fulfilled, (state, action) => {
        state.loadingForModal = false;
        state.searchedGames = action.payload;
      })
      .addCase(searchGamesThunk.rejected, (state, action) => {
        state.loadingForModal = false;
        state.error = action.error.message || 'Failed to create tag';
      })
      .addCase(updateTagThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTagThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = state.tags.map(tag =>
          tag.id === action.payload.id ? action.payload : tag,
        );
      })

      .addCase(updateTagThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update tag';
      })
      .addCase(getTagGamesThunk.pending, state => {
        state.loadingForModal = true;
        state.error = null;
      })
      .addCase(getTagGamesThunk.fulfilled, (state, action) => {
        state.loadingForModal = false;
        state.selectedFullTagInfo = [
          ...(state.selectedFullTagInfo || []),
          ...action.payload.data.gamesList,
        ];
        state.gameCount = action.payload.data.count;
      })
      .addCase(getTagGamesThunk.rejected, (state, action) => {
        state.loadingForModal = false;
        state.error = action.error.message || 'Failed to fetch tag games';
      });
  },
});

export const {
  setLoading,
  setError,
  setSelectedTag,
  updateTag,
  addTag,
  clearSearchGames,
  removeGameFromTag,
  removeTag,
  clearSelctedFullTgaInfo,
  clearFilters,
} = tagsSlice.actions;

export default tagsSlice.reducer;
