import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { fetchCasinoAggregatorsThunks } from 'redux/reducers/casino/aggregators/aggregators.thunks';
import { RootState } from 'redux/store.types';
import { fetchProjectList } from 'redux/reducers/projects/project.thunks';

import classes from './components/Aggregators.module.scss';
import AggregatorsAccordion from './components/AggregatorsAccordion';
import AgreggatorsFilters from './components/AggregatorsFilters';

const Aggregators: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading } = useSelector((state: RootState) => state.aggregators);

  useEffect(() => {
    dispatch(fetchCasinoAggregatorsThunks());
    dispatch(fetchProjectList());
  }, []);

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          <AgreggatorsFilters />
          <Divider className={classes.divider} />
          <AggregatorsAccordion />
        </>
      )}
    </>
  );
};

export default Aggregators;
