/* eslint-disable */
import {
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Button,
  Upload,
  message,
  AutoComplete,
  AutoCompleteProps,
  Tooltip,
} from 'antd';
import { Option } from 'antd/es/mentions';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store.types';
import {
  getDenominationsAndBetsThunk,
  searchFreeSpinsThunk,
  uploadExcelFileThunk,
} from 'redux/reducers/freeSpins/freeSpins.thunks';
import { selectAuthUserInfo } from 'redux/reducers/serverConfigs/serverConfigs.slice';

import { clearGames } from 'redux/reducers/freeSpins/freeSpins.slice';

import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import moment, { isMoment } from 'moment';
import dayjs from 'dayjs';

const Settings: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<File | null>(null);
  const [options, setOptions] = useState<AutoCompleteProps['options']>([]);

  const [formData, setFormData] = useState({
    gameId: '',
    denomination: '',
    betId: '',
    validUntil: '',
    quantity: '',
    playerId: '',
    file: file,
  });

  const authInfo = useSelector(selectAuthUserInfo);

  const { games, uploadLoading } = useSelector(
    (state: RootState) => state.freeSpins,
  );

  const { denomination, bets } = useSelector(
    (state: RootState) => state.freeSpins.settings,
  );

  useEffect(() => {
    setOptions(games);
  }, [games]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };
  const uploadProps = {
    beforeUpload: (file: any) => {
      const isXlsx = file.name.toLowerCase().endsWith('.xlsx');
      if (!isXlsx) {
        message.error(`${file.name} is not an XLSX file`);
        return Upload.LIST_IGNORE;
      } else {
        message.success(`${file.name} is a valid XLSX file`);
      }
      return false;
    },
    onChange: (info: any) => {
      setFile(info.fileList[0]?.originFileObj || null);
    },
  };
  const disabledDate = (current: moment.Moment) => {
    return current.isBefore(moment().startOf('day'));
  };

  const validateForm = () => {
    if (
      !formData.gameId ||
      !formData.denomination ||
      !formData.betId ||
      !formData.quantity ||
      !formData.validUntil ||
      !(formData.playerId || file)
    ) {
      message.error('Please fill in all required fields .');
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    const adjustedValidUntil = moment(formData.validUntil)
      .subtract(4, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    dispatch(
      uploadExcelFileThunk({
        quantity: Number(formData.quantity),
        denomination: Number(formData.denomination),
        betId: Number(formData.betId),
        playerId: formData.playerId,
        validUntil: adjustedValidUntil,
        gameId: Number(formData.gameId),
        file: file ? file : undefined,
      }),
    )
      .unwrap()
      .then(() => {
        message.success('File uploaded successfully!');
        window.location.reload();
      })
      .catch(error => {
        message.error(`Error: ${error}`);
      });
  };

  const onSelect = (data: string) => {
    const selectedItem = games.find((games: any) => games.name === data);

    formData.gameId = selectedItem.id;
    if (selectedItem) {
      dispatch(getDenominationsAndBetsThunk(selectedItem.uuid));
    }
  };

  const onSearchGame = (text: string) => {
    if (text.length <= 2) {
      dispatch(clearGames());
    } else {
      dispatch(searchFreeSpinsThunk(text));
    }
  };

  return (
    <>
      {uploadLoading ? (
        <GlobalLoader />
      ) : (
        <div style={{ padding: '20px' }}>
          <Row
            gutter={[90, 50]}
            style={{ justifyContent: 'center', marginBottom: '30px' }}>
            <Col span={8}>
              Search games
              <AutoComplete
                onSelect={onSelect}
                options={options}
                style={{ width: '100%' }}
                onSearch={text => onSearchGame(text)}
                placeholder='Search by name'
              />
            </Col>
            <Col span={8}>
              Date To
              <DatePicker
                style={{ width: '100%' }}
                onChange={date => {
                  if (date) {
                    formData.validUntil = date.format('YYYY-MM-DD HH:mm:ss');
                  }
                }}
                showTime
                disabledDate={current => current.isBefore(dayjs(), 'day')}
              />
            </Col>
          </Row>
          <Row
            gutter={[90, 50]}
            style={{ justifyContent: 'center', marginBottom: '30px' }}>
            <Col span={8}>
              Denomination
              <Select
                onChange={e => (formData.denomination = e)}
                disabled={denomination.length < 1}
                style={{ width: '100%' }}
                placeholder='Choose a denomination'>
                {denomination?.map((item: any) => {
                  return (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={8}>
              Bets
              <Select
                style={{ width: '100%' }}
                onChange={e => (formData.betId = e)}
                disabled={bets.length < 1}
                placeholder='Choose  bets'>
                {bets?.map((item: any) => {
                  return (
                    <Option key={item.bet_id} value={item.bet_id}>
                      {JSON.stringify(item)}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row
            gutter={[90, 50]}
            style={{ justifyContent: 'center', marginBottom: '30px' }}>
            <Col span={8}>
              Player ID
              <Input
                onChange={e => handleChange(e, 'playerId')}
                disabled={!file ? false : true}
                placeholder='player id '
              />
            </Col>
            <Col span={8}>
              Quantity
              <Input
                onChange={e => handleChange(e, 'quantity')}
                placeholder='Enter Quantity'
              />
            </Col>
          </Row>

          <Row
            gutter={[90, 50]}
            style={{ justifyContent: 'center', marginBottom: '30px' }}>
            {/* <Col span={3}>
              <Upload
                headers={{
                  'X-Auth-Token': authInfo.token,
                }}
                beforeUpload={uploadProps.beforeUpload}
                onChange={uploadProps.onChange}
                maxCount={1}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Col> */}

            <Col span={5}>
              <Button
                style={{ width: '100%' }}
                icon={<DownloadOutlined />}
                href='/ExcelExample.numbers'
                type='primary'
                download>
                Download Template
              </Button>
            </Col>
            <Col span={8}></Col>
          </Row>
          <Tooltip
            title={
              denomination.length < 1 ? 'The Provider Has not FreeSpins' : ''
            }>
            <Button
              style={{
                marginLeft: '600px',
                marginTop: '120px',
                width: '100px',
              }}
              type='primary'
              onClick={handleSave}
              disabled={denomination.length < 1}>
              Save
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default Settings;
