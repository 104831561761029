/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Table, Empty } from 'antd';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';

import { useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  fetchGameById,
  filterGamesThunk,
  searchGamesThunk,
  updateCurrentGameThunks,
} from 'redux/reducers/casino/games/games.thunks';
import { GeneralGamesTableConfig } from 'redux/reducers/casino/games/games.types';

import EditGameModal from './EditGameModal';
import { RootState } from 'redux/store.types';
import { useSelector } from 'react-redux';
import img from '../../../../../assets/images/icon.jpg';
import { deviecSupportConfigs } from '../gamesConfig/generalGamesTable.config';
import { selectAuthUserInfo } from 'redux/reducers/serverConfigs/serverConfigs.slice';

const GeneralGamesTable: React.FC<GeneralGamesTableConfig> = ({
  generalGamesTableConfig,
  setCurrentPage,
  currentPage,
  checkedList,
}) => {
  const dispatch = useAppDispatch();
  const { allGames, gameCount } = useSelector((state: any) => state.games);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingGame, setEditingGame] = useState<any>(null);
  const [gameForUpdate, setGameForUpdate] = useState<any>(null);
  const [pageSize, setPageSize] = useState(20);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { loading } = useSelector((state: RootState) => state.games);
  const [order, setOrder] = useState('');
  const [columnKey, setColumnKey] = useState('');
  const [searchData, setSearchData] = useState({
    selectedKeys: '',
    dataIndex: '',
  });
  const authInfo = useSelector(selectAuthUserInfo);

  const handleEdit = (record: any) => {
    dispatch(fetchGameById(record.id.toString()));
    setEditingGame(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (gameForUpdate && Object.keys(gameForUpdate).length > 0) {
      console.log(gameForUpdate);
      dispatch(
        updateCurrentGameThunks({
          editingGame: gameForUpdate,
          generalInfo: editingGame,
        }),
      );
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingGame(null);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const handleSearchWithFilters = () => {
    const cleanedKey = searchKeyword.trim();
    if (searchKeyword) {
      dispatch(
        searchGamesThunk({
          keyword: cleanedKey,
          siteSection: 'casino',
          mobile: false,
        }),
      );
    }
  };

  const handleSearchh = (selectedKeys: string[], dataIndex: any) => {
    const cleanedKey = selectedKeys[0].trim();
    setSearchData({ selectedKeys: cleanedKey, dataIndex });
    setCurrentPage(1);
    dispatch(
      filterGamesThunk({
        body: {
          [dataIndex]: cleanedKey,
        },
        additionalProps: {
          limit: 20,
          currentPage: currentPage,
        },
      }),
    );
  };

  const handleTableChange = (page: any, filters: any, sorter: any) => {
    const sortValue =
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : '';
    Object.keys(sorter).length > 0 && setColumnKey(sorter.columnKey);
    sortValue !== '' && setOrder(sortValue);
    dispatch(
      filterGamesThunk({
        body: {
          [searchData.dataIndex]: searchData?.selectedKeys,
          visibility: checkedList.visibility,
          type: checkedList.type,
          hasFreespins: checkedList.freeSpins,
          hasDemo: checkedList.hasDemo,
          siteSection: (checkedList.siteSection as string[]).map(
            item => item.charAt(0).toLowerCase() + item.slice(1),
          ),
          deviceSupport: Object.entries(deviecSupportConfigs)
            .filter(
              ([key, value]) =>
                Array.isArray(checkedList.deviceSupport) &&
                checkedList.deviceSupport.some((item: any) => item === value),
            )
            .map(([key]) => key),
        },
        additionalProps: {
          limit: 20,
          sort: sorter.columnKey ? sorter.columnKey : '',
          order: sortValue ? sortValue : '',
          currentPage: page.current,
        },
      }),
    );
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    dispatch(
      filterGamesThunk({
        body: {},
        additionalProps: {
          limit: 20,
          currentPage: currentPage,
        },
      }),
    );
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }: any) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ marginBottom: 8, display: 'block' }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchh(selectedKeys as string[], dataIndex);
            }
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchh(selectedKeys as string[], dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const updatedColumns = generalGamesTableConfig?.map((col: any) => ({
    ...col,
    ...(['aggregator', 'provider', 'url'].includes(col.dataIndex)
      ? getColumnSearchProps(col.dataIndex)
      : {}),
    title: col.title,
  }));

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Input
          value={searchKeyword}
          onChange={e => setSearchKeyword(e.target.value)}
          placeholder='Search for a game'
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearchWithFilters();
            }
          }}
        />
        <Button onClick={handleSearchWithFilters} type='primary'>
          Search
        </Button>
      </Space>

      <Table
        loading={loading}
        columns={[
          updatedColumns[0],
          {
            title: 'Edit',
            key: 'actions',
            render: (record: any) => (
              <EditTwoTone onClick={() => handleEdit(record)} />
            ),
          },
          ...updatedColumns.slice(1),
        ]}
        dataSource={allGames}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: gameCount,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
        scroll={{
          x: 'max-content',
        }}
        locale={{
          emptyText: (
            <div style={{ textAlign: 'center' }}>
              <Empty image={<img src={img} />} description='No Game Found' />
            </div>
          ),
        }}
      />

      <EditGameModal
        visible={isModalVisible}
        editingGame={editingGame}
        setEditingGame={setGameForUpdate}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default GeneralGamesTable;
