/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchColorConfigsThunks } from './colorConfigs.thunks';
import { parseCSSVariables } from 'components/Routes/ColorConfigs/ColorConfigs';

const initialState: any = {
  loading: false,
  colorsConfigs: '',
  error: null,
};

const colorConfigsSlice = createSlice({
  name: 'colorConfigs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchColorConfigsThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchColorConfigsThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          if (action.payload.websiteTheme) {
            state.colorsConfigs = parseCSSVariables(
              action.payload.websiteTheme,
            );
          } else {
            state.colorsConfigs = null;
          }
        },
      )
      .addCase(fetchColorConfigsThunks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch general games';
      });
  },
});

export default colorConfigsSlice.reducer;
