import dayjs from 'dayjs';

import { CheckboxGroupProps } from 'redux/reducers/casino/games/games.types';

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const deviecSupportConfigs: any = {
  0: 'Desktop',
  1: 'Mobile',
  2: 'Mobile & Desktop',
};

export const deletedBy: any = {
  0: 'None Deleted',
  1: 'By Operator',
  2: 'By Operator & By Provider',
};

export const generalGamesTableConfig = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Aggregator', dataIndex: 'aggregator', key: 'aggregator' },
  { title: 'Provider', dataIndex: 'provider', key: 'provider' },
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Site Section', dataIndex: 'siteSection', key: 'siteSection' },
  {
    title: 'Deleted',
    dataIndex: 'deleted',
    key: 'deleted',
    render: (text: number) => {
      return deletedBy[text];
    },
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    render: (text: boolean) => (text ? 'Visible' : 'Hidden'),
  },

  {
    title: 'Free Spins',
    dataIndex: 'hasFreespins',
    key: 'hasFreespins',
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Device Support',
    dataIndex: 'deviceSupport',
    key: 'deviceSupport',
    render: (text: number) => {
      return deviecSupportConfigs[text];
    },
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    sorter: (a: any, b: any) => a.priority - b.priority,
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (image: string) => (
      <img src={image} alt='game' style={{ width: 50 }} />
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a: any, b: any) => a.createdAt - b.createdAt,
    render: (text: string) => dayjs(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sorter: (a: any, b: any) => a.updatedAt - b.updatedAt,
    render: (text: string) => dayjs(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: 'Percent 1',
    dataIndex: 'percent1',
    key: 'percent1',
  },
  {
    title: 'Percent 2',
    dataIndex: 'percent2',
    key: 'percent2',
  },

  {
    title: 'Demo Available',
    dataIndex: 'hasDemo',
    key: 'hasDemo',
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
];

export const checkboxGroups: CheckboxGroupProps[] = [
  {
    label: 'Visibility',
    name: 'visibility',
    options: ['Visible', 'Hidden'],
  },

  {
    label: 'Site Section',
    name: 'siteSection',
    options: ['casino', 'virtual-sport', 'live-casino'].map(
      capitalizeFirstLetter,
    ),
  },
  {
    label: 'Type',
    name: 'type',
    options: ['Video-Slots', 'Slots'],
  },
  {
    label: 'Free Spins',
    name: 'freeSpins',
    options: ['Yes', 'No'],
  },
  {
    label: 'Demo Available',
    name: 'hasDemo',
    options: ['Yes', 'No'],
  },
  {
    label: 'Device Support',
    name: 'deviceSupport',
    options: ['Mobile', 'Desktop', 'Mobile & Desktop'],
  },
];

export const modalConfig: any = {
  name: 'Name',
  hasFreespins: 'Has freespins',
  hasDemo: 'Has demo',
  url: 'URL',
  deviceSupport: 'Device Support',
  priority: 'Priority',
  markerType: 'Marker Color ',
  markerText: 'Marker text',
  deleted: 'Game Status',
  frameSize: 'Frame size',
  type: 'Type',
  percent1: 'Percent_1',
  percent2: 'Percent_2',
  visibility: 'Visibility',
  aggregator: 'Aggregator',
  provider: 'Provider',
  siteSection: 'Site section',
  tagName: 'Tags',
};

export const singleChoicemodalConfig = [
  'frameSize',
  'deleted',
  'visibility',
  'siteSection',
];
export const selectConfigs: any = {
  hasFreespins: { true: 'Yes', false: 'No' },
  hasDemo: { true: 'Yes', false: 'No' },
  visibility: { true: 'Shown', false: 'Hidden' },
  deleted: { 1: 'Deleted by operator', 2: 'Deleted by aggregator' },
  frameSize: { 0: 'Normal Screen', 1: 'Full Screen' },
  siteSection: {
    casino: 'Casino',
    'live-casino': 'Live Casino',
    'virtual-sport': 'Virtual Sport',
  },
};
export const readOnlymodalConfig = [
  'hasFreespins',
  'hasDemo',
  'deviceSupport',
  'aggregator',
  'provider',
  'tagName',
];
export const readOnlymodalConfigForChange: Record<
  string,
  Record<string, string>
> = {
  hasFreespins: { true: 'Yes', false: 'No' },
  hasDemo: { true: 'Yes', false: 'No' },
  deviceSupport: { 0: 'Desktop', 1: 'Mobile', 2: 'Desktop & Mobile' },
};
