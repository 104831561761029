/* eslint-disable */
import { Button, Card, Space, Tag as AntTag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FC } from 'react';

interface TagData {
  id: string;
  name: string;
  priority: number;
  deleted: boolean;
  gameCount: number;
}

interface TagCardProps {
  tag: TagData;
  onEdit: (tag: TagData) => void;
  onDelete: (tagId: string) => void;
  onClick: (tag: TagData) => void;
}

const TagCard: FC<TagCardProps> = ({ tag, onEdit, onDelete, onClick }) => {
  const handleDelete = async (e: React.MouseEvent, tagId: string) => {
    e.stopPropagation();
    onDelete(tagId);
  };

  return (
    <Card
      title={tag.name}
      extra={
        <Button
          type='text'
          danger
          icon={<DeleteOutlined />}
          onClick={e => handleDelete(e, tag.id)}
        />
      }
      onClick={() => onClick(tag)}
      hoverable
      actions={[
        <Button
          type='link'
          icon={<EditOutlined />}
          onClick={e => {
            e.stopPropagation();
            onEdit(tag);
          }}>
          Edit
        </Button>,
      ]}>
      <Space direction='vertical'>
        <div>
          Priority: <AntTag color='blue'>{tag.priority}</AntTag>
        </div>
        <div>
          Status:{' '}
          <AntTag color={tag.deleted ? 'red' : 'green'}>
            {tag.deleted ? 'Deleted' : 'Active'}
          </AntTag>
        </div>
      </Space>
    </Card>
  );
};

export default TagCard;
