/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  freeSpinsFilterThunks,
  getDenominationsAndBetsThunk,
  searchFreeSpinsThunk,
  uploadExcelFileThunk,
} from './freeSpins.thunks';

interface FreeSpinGame {
  id: number;
  name: string;
}

interface FreeSpinsState {
  freeSpinGames: FreeSpinGame[];
  count: number;
  loading: boolean;
  error: string | null;
  uploadLoading: boolean;
  uploadError: string | null;
  games: any;
  settings: any;
}

const initialState: FreeSpinsState = {
  freeSpinGames: [],
  count: 0,
  loading: false,
  error: null,
  uploadLoading: false,
  uploadError: null,
  games: [],
  settings: {
    betLevel: [],
    bets: [],
    denomination: [],
  },
};

const freeSpinsSlice = createSlice({
  name: 'freeSpins',
  initialState,
  reducers: {
    clearGames: state => {
      state.games = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(freeSpinsFilterThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        freeSpinsFilterThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.freeSpinGames = action.payload.freespinsList;
          state.count = action.payload.count;
        },
      )
      .addCase(freeSpinsFilterThunks.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch free spins games';
      })
      .addCase(uploadExcelFileThunk.pending, state => {
        state.uploadLoading = true;
        state.error = null;
      })
      .addCase(
        uploadExcelFileThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.uploadLoading = false;
        },
      )
      .addCase(uploadExcelFileThunk.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error =
          action.error.message || 'Failed to fetch free spins games';
      })
      .addCase(searchFreeSpinsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchFreeSpinsThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          if (Array.isArray(action.payload)) {
            state.games = action.payload.map((item: any) => ({
              ...item,
              label: `${item.name} | ${item.aggregator} | ${item.provider}`,
              value: item.name,
            }));
          } else {
            console.error('Expected an array but received:', action.payload);
            state.games = [];
          }
        },
      )
      .addCase(searchFreeSpinsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || 'Failed to search free spins games';
      })
      .addCase(getDenominationsAndBetsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDenominationsAndBetsThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.settings.betLevel = action.payload?.betLevel;
          state.settings.bets = action.payload?.bets;
          state.settings.denomination = action.payload?.denomination;
        },
      )
      .addCase(getDenominationsAndBetsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch free spins games';
      });
  },
});

export const { clearGames } = freeSpinsSlice.actions;

export default freeSpinsSlice.reducer;
