/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  message,
  Modal,
  Pagination,
  Input,
  Cascader,
} from 'antd';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { RootState } from 'redux/store.types';
import { TagData } from 'redux/reducers/tags/types';
import { setSelectedTag } from 'redux/reducers/tags/tags.slice';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import TagCard from './TagCard';
import EditTagModal from './EditTagModal';
import {
  fetchTags,
  deleteTagThunk,
  createTagThunk,
  updateTagThunk,
} from 'redux/reducers/tags/tags.thunks';
import GamesModal from './GamesModal';

const sortingOptions = [
  {
    value: 'name',
    label: 'Name',
    children: [
      { value: 'asc', label: 'Ascending' },
      { value: 'desc', label: 'Descending' },
    ],
  },
  {
    value: 'priority',
    label: 'Priority',
    children: [
      { value: 'asc', label: 'Ascending' },
      { value: 'desc', label: 'Descending' },
    ],
  },
  {
    value: 'id',
    label: 'ID',
    children: [
      { value: 'asc', label: 'Ascending' },
      { value: 'desc', label: 'Descending' },
    ],
  },
];

const Tags: FC = () => {
  const dispatch = useAppDispatch();
  const { tags, loading, error, selectedTag, count } = useSelector(
    (state: RootState) => state.tags,
  );

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isGamesModalVisible, setIsGamesModalVisible] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [searchVal, setSearchVal] = useState('');
  const [sortParams, setSortParams] = useState({ field: 'id', order: 'asc' });
  const [selectedButton, setSelectedButton] = useState<string>('');
  const [cascaderValue, setCascaderValue] = useState<any>([]);

  useEffect(() => {
    const fetchParams: any = {
      page: currentPage,
      limit: pageSize,
      sort: sortParams.field,
      order: sortParams.order,
    };
    const body: any = searchVal ? { name: searchVal } : {};
    if (selectedButton) {
      body.deleted = selectedButton;
    }
    dispatch(fetchTags({ params: fetchParams, body }));
  }, [currentPage, pageSize, dispatch, selectedButton, sortParams]);

  const handleDelete = (tagId: string) => {
    Modal.confirm({
      title: 'Delete Tag',
      content: 'Are you sure you want to delete this tag?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await dispatch(deleteTagThunk([tagId])).unwrap();
          message.success('Tag deleted successfully');
        } catch (error) {
          message.error('Failed to delete tag');
        }
      },
    });
  };

  const handleSearch = () => {
    if (!searchVal.trim()) {
      return;
    }

    setCurrentPage(1);
    dispatch(
      fetchTags({
        params: {
          page: 1,
          limit: pageSize,
          sort: sortParams.field,
          order: sortParams.order,
        },
        body: { name: searchVal },
      }),
    );
  };

  const handleSortChange = (value: string[]) => {
    if (value.length === 2) {
      setCurrentPage(1);
      setSortParams({ field: value[0], order: value[1] });
      setCascaderValue(value);
    }
  };

  const handleEdit = (tag: TagData) => {
    dispatch(setSelectedTag(tag));
    setIsEditModalVisible(true);
  };

  const handleTagClick = (tag: TagData) => {
    dispatch(setSelectedTag(tag));
    setIsGamesModalVisible(true);
  };

  const handleCreateTag = () => {
    dispatch(setSelectedTag(null));
    setIsCreateMode(true);
    setIsEditModalVisible(true);
  };

  const handleModalOk = async (tagData: TagData) => {
    try {
      if (isCreateMode) {
        await dispatch(createTagThunk(tagData)).unwrap();
        message.success('Tag created successfully');
        setIsEditModalVisible(false);
        setIsCreateMode(false);
      } else {
        await dispatch(updateTagThunk(tagData)).unwrap();
        message.success('Tag updated successfully');
        setIsEditModalVisible(false);
      }
    } catch (error: any) {
      message.error(
        'Failed to ' +
          (isCreateMode ? 'create' : 'update') +
          ' tag: ' +
          (error.message || 'Unknown error'),
      );
    }
  };

  if (loading) {
    return <GlobalLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ padding: '24px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}>
        <h1>Tags</h1>
        <Button type='primary' onClick={handleCreateTag}>
          Create Tag
        </Button>
      </div>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Input
            placeholder='Search by name'
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            style={{ width: '300px', marginRight: '8px' }}
          />

          <Button type='primary' onClick={handleSearch}>
            Search
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Button.Group>
            <Button
              type={selectedButton === 'active' ? 'primary' : 'default'}
              onClick={() => setSelectedButton('false')}>
              Active Tags
            </Button>
            <Button
              type={selectedButton === 'deleted' ? 'primary' : 'default'}
              onClick={() => setSelectedButton('true')}>
              Deleted Tags
            </Button>
            <Button
              type={selectedButton === '' ? 'primary' : 'default'}
              onClick={() => setSelectedButton('')}>
              All Tags
            </Button>
          </Button.Group>
        </Col>
        <Col>
          <Cascader
            value={cascaderValue}
            options={sortingOptions}
            onChange={handleSortChange}
            placeholder='Sort by'
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        {tags && tags.length > 0 ? (
          tags.map((tag: TagData) => (
            <Col xs={24} sm={12} md={8} lg={6} key={tag.id}>
              <div style={{ textAlign: 'center' }}>
                <TagCard
                  tag={tag}
                  onEdit={() => handleEdit(tag)}
                  onDelete={() => handleDelete(tag.id)}
                  onClick={() => handleTagClick(tag)}
                />
              </div>
            </Col>
          ))
        ) : (
          <Col span={24}>
            <div style={{ textAlign: 'center' }}>No tags found</div>
          </Col>
        )}
      </Row>

      <div style={{ marginTop: '24px', textAlign: 'center' }}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={count}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          }}
        />
      </div>
      <EditTagModal
        isVisible={isEditModalVisible}
        onCancel={() => {
          setIsEditModalVisible(false);
          setIsCreateMode(false);
        }}
        onOk={handleModalOk}
        tag={selectedTag}
        isCreateMode={isCreateMode}
      />
      {isGamesModalVisible && (
        <GamesModal
          isVisible={isGamesModalVisible}
          onCancel={() => setIsGamesModalVisible(false)}
        />
      )}
    </div>
  );
};

export default Tags;
