/* eslint-disable */

import { Tabs } from 'antd';
import { FC, useEffect } from 'react';

import ManualAndAutomatized from './ManualAndAutomatized/ManualAndAutomatized';
import Settings from './Settings/Settings';
import classes from './ManualAndAutomatized/ManualAndAutomatized.module.scss';
import style from './FreeSpins.module.scss';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { fetchProjectList } from 'redux/reducers/projects/project.thunks';

const FreeSpins: FC = () => {
  const dispatch = useAppDispatch();

  const items = [
    {
      key: '1',
      label: <span className={style.tabHeader}>Manual & Automatized</span>,
      children: (
        <div className={classes.ManualAndAutomatizedContainer}>
          <ManualAndAutomatized />
        </div>
      ),
    },
    {
      key: '2',
      label: <span className={style.tabHeader}>Settings</span>,
      children: (
        <div className={classes.settingsContainer}>
          <Settings />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchProjectList());
  }, []);

  return (
    <div className={classes.container}>
      <Tabs defaultActiveKey='1' items={items} />
    </div>
  );
};

export default FreeSpins;
