/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Spin } from 'antd';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { RootState } from 'redux/store.types';
import { freeSpinsFilterThunks } from 'redux/reducers/freeSpins/freeSpins.thunks';
import { columns } from './ManualAndAutomatized.config';
import classes from './ManualAndAutomatized.module.scss';
import dayjs from 'dayjs';

interface FreeSpinsTableProps {
  filtersData: any;
  currentPage: number;
  setCurrentPage: any;
}

const FreeSpinsTable: React.FC<FreeSpinsTableProps> = ({
  filtersData,
  currentPage,
  setCurrentPage,
}) => {
  const { freeSpinGames, loading, count } = useSelector(
    (state: RootState) => state.freeSpins,
  );
  const dispatch = useAppDispatch();
  const [pageSize, setPageSize] = useState(20);

  const [order, setOrder] = useState('');
  const [columnKey, setColumnKey] = useState('');

  useEffect(() => {
    setCurrentPage(1);
  }, [order, columnKey]);

  const cleanFilters = (
    filtersData: Record<string, any>,
  ): Record<string, any> => {
    return Object.entries(filtersData).reduce((acc, [key, value]) => {
      if (
        value === '' ||
        (key === 'createdAt' &&
          Array.isArray(value) &&
          value.every(item => item === ''))
      ) {
        return acc;
      }
      acc[key] = value;
      return acc;
    }, {} as Record<string, any>);
  };

  useEffect(() => {
    const cleanedFilters = cleanFilters(filtersData);
    dispatch(
      freeSpinsFilterThunks({
        addProps: {
          pageSize,
          currentPage,
          sort: columnKey || 'id',
          order: order || 'desc',
        },
        body: { ...cleanedFilters },
      }),
    );
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const sortValue =
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : '';

    Object.keys(sorter).length > 0 && setColumnKey(sorter.columnKey);
    sortValue !== '' && setOrder(sortValue);
    const cleanedFilters = cleanFilters(filtersData);
    dispatch(
      freeSpinsFilterThunks({
        addProps: {
          pageSize,
          currentPage: pagination.current,
          sort: sorter.columnKey ? sorter.columnKey : '',
          order: sortValue ? sortValue : '',
        },
        body: { ...cleanedFilters },
      }),
    );
  };

  return (
    <Spin spinning={loading}>
      <Table
        className={classes.generalFreeSpinsTable}
        columns={columns}
        dataSource={freeSpinGames}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: count,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
          showSizeChanger: false,
        }}
      />
    </Spin>
  );
};

export default FreeSpinsTable;
