/* eslint-disable */

import { Table, Input, Button, Checkbox } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { clearSearchGames } from 'redux/reducers/tags/tags.slice';
import { searchGamesThunk } from 'redux/reducers/tags/tags.thunks';
import { RootState } from 'redux/store.types';

interface AddGamesToTagProps {
  tagId: string;
  selectedFullTagInfo: any;
  onSelectedGamesChange: (selectedGames: string[]) => void;
}

interface Game {
  id: string;
  name: string;
  provider: string;
  aggregator: string;
}

const AddGamesToTag: FC<AddGamesToTagProps> = ({
  onSelectedGamesChange,
  selectedFullTagInfo = [],
}) => {
  const dispatch = useAppDispatch();
  const searchResults = useSelector(
    (state: RootState) => state.tags.searchedGames,
  );
  const loadingForModal = useSelector(
    (state: RootState) => state.tags.loadingForModal,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedForAddition, setSelectedForAddition] = useState<string[]>([]);

  useEffect(() => {
    if (searchQuery.length < 3) {
      dispatch(clearSearchGames());
    }
  }, [searchQuery]);

  const handleSearch = () => {
    dispatch(searchGamesThunk(searchQuery));
  };

  const handleCheckboxChange = (gameId: string, checked: boolean) => {
    const idAsString = String(gameId);
    const newSelected = checked
      ? [...selectedForAddition, idAsString]
      : selectedForAddition.filter(id => id !== idAsString);

    setSelectedForAddition(newSelected);
    onSelectedGamesChange(newSelected);
  };

  const selectedIds = selectedFullTagInfo?.map((game: Game) => game.id);
  const filteredSearchResults = searchResults.filter(
    (game: Game) => !selectedIds.includes(game.id.toString()),
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Game Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Aggregator',
      dataIndex: 'aggregator',
      key: 'aggregator',
    },
    {
      title: 'Check for Add',
      key: 'actions',
      render: (record: Game) => (
        <Checkbox
          onChange={e =>
            handleCheckboxChange(String(record.id), e.target.checked)
          }
          checked={selectedForAddition.includes(String(record.id))}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '16px' }}>
        <Input
          placeholder='Search games...'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <Button type='primary' onClick={handleSearch} loading={loadingForModal}>
          Search
        </Button>
      </div>
      {filteredSearchResults?.length > 0 && (
        <Table
          loading={loadingForModal}
          dataSource={filteredSearchResults || []}
          columns={columns}
          pagination={{ pageSize: 5 }}
        />
      )}
    </div>
  );
};

export default AddGamesToTag;
