export const colors = {
  '--wex-black': '0, 0, 0',
  '--wex-very-dark-blue': '19, 22, 28',
  '--wex-white': '255, 255, 255',
  '--wex-purple': '145, 25, 166',
  '--wex-wet-asphlat': '28, 33, 41',
  '--wex-electric-green': '5, 205, 0',
  '--wex-silver': '167, 169, 173',
  '--result-search-background': '35, 41, 51',
  '--wex-left-menu-champion-ship': '29, 33, 42',
  '--wex-left-menu-drop-down-item': '35, 41, 51',
  '--wex-right-column-live': '40, 47, 60',
  '--wex-icons': '142, 199, 20',
  '--wex-icons-active': '255, 255, 255',
  '--wex-white-text': '255, 255, 255',
  '--wex-error-text': '255, 0, 0',
  '--live-black': '#13161C',
  '--live-green': '#8EC714',
  '--live-darkgreen': '#019A00',
  '--live-bg-active': '#1C2129',
  '--live-gray': '#232933',
  '--live-league-bg': '#282F3C',
  '--live-red': '#CD0013',
  '--live-details-white': '#FFFFFF',
  '--live-white': '#FAFAFA',
  '--live-bet-white': '#EAEAEA',
  '--live-bet-gray': '#F4F4F4',
  '--live-bet-border1': '#262C36',
  '--live-bet-border2': '#3E434C',
  '--live-chart-white': '#EEEEEE',
  '--live-events-white': '250, 250, 250',
  '--popup-background': '39, 39, 39',
  '--live-main-color': '#8EC714',
  '--live-price-bg': '#13161C',
  '--live-text-color': '#FAFAFA',
  '--live-text-active': '#FFFFFF',
  '--live-bg-color': '#232933',
  '--live-price-color': '#13161C',
  '--live-arrow-color': '#FAFAFA',
  '--color-np-white': 'rgb(255, 255, 255)',
  '--color-np-white-7': 'rgba(255, 255, 255, 0.7)',
  '--color-np-white2': 'rgb(254, 255, 255)',
  '--color-np-black': 'rgb(7, 21, 34)',
  '--color-np-green': 'rgb(177, 229, 61)',
  '--color-np-green1': 'rgb(70, 142, 107)',
  '--color-np-green2': 'rgb(115, 142, 57)',
  '--color-np-gray': 'rgb(33, 47, 56)',
  '--color-np-blue': 'rgb(43, 160, 196)',
  '--color-np-blue2': 'rgb(42, 67, 83)',
  '--color-np-blue3': 'rgb(9, 40, 60)',
  '--wex-light-green': '142, 199, 20',
  '--wex-very-dark-bluewex-black': '19, 22, 28',
  '--color-np-white--7': 'rgba(255, 255, 255, 0.7)',
  '--color-np-blue4': 'rgb(8, 35, 53)',
  '--color-np-blue5': 'rgb(15, 63, 85)',
  '--color-np-blue6': 'rgb(16, 63, 84)',
  '--color-np-blue7': 'rgb(41, 154, 189)',
  '--color-np-blue8': 'rgb(8, 31, 47)',
  '--color-np-darkblue--6': 'rgba(7, 8, 10, 0.6)',
  '--color-np-gold': 'rgb(255, 189, 48)',
  '--primary-transparent': '#302a3ccc',
  '--rs-promo-border': '21, 22, 29',
  '--rs-promo-white': '#F3F3F3',
  '--rs-promo-silver': '#EBEBEB',
  '--rs-promo-bet-text': '#E2E2E2',
  '--rs-termconf-promo-txt': '#F1F1F1',
  '--rs-promo-light-silver': '#FAFAFA',
  '--rs-promo-gray': '#BFBFBF',
  '--rs-promo-dark-gray': '112, 112, 112',
  '--rs-promo-black': '#000000',
  '--rs-promo-bg': '#13161D',
  '--rs-promo-popup-bg': '#15161D',
  '--rs-promo-popup-main-bg': '20, 21, 28',
  '--rs-promo-content-bg': '39, 47, 60',
  '--rs-promo-ldboard-item-bg': '#1C2029',
  '--rs-promo-light-purple': '145, 25, 166',
  '--rs-promo-dark-purple': '62, 0, 72',
  '--rs-promo-purple': '133, 24, 153',
  '--rs-promo-shadowed-purple': '#674D81',
  '--rs-promo-green': '#8EC814',
  '--rs-promo-green-txt': 'rgb(105, 185, 121)',
  '--rs-promo-yellow': '#E0C900',
  '--rs-promo-popup-main-mobile-bg': '1, 2, 2',
  '--color-black': 'rgb(0, 0, 0)',
  '--color-white': 'rgb(255, 255, 255)',
  '--color-black--9': 'rgba(0, 0, 0, 0.9)',
  '--color-luckyBg': 'rgb(24, 14, 41)',
  '--color-luckyViolet': 'rgba(150, 126, 230)',
  '--color-luckyViolet2': 'rgb(147, 122, 226)',
  '--color-luckyPurpule--14': 'rgba(91, 78, 183, 0.14)',
  '--color-luckyPurpule--22': 'rgba(91, 78, 183, 0.22)',
  '--color-luckyPurpule1': 'rgb(113, 88, 194)',
  '--color-luckyGreen': 'rgb(44, 175, 105)',
  '--color-luckyGreen--22': 'rgba(44, 175, 105, 0.22)',
  '--color-luckyGreen1': 'rgb(60, 162, 102)',
  '--color-luckyGreen2': 'rgb(91, 196, 136)',
  '--color-luckyGreen3': 'rgb(44, 146, 99)',
  '--color-luckyGreen4': 'rgb(30, 81, 51)',
  '--color-luckyGreen4--65': 'rgba(164, 221, 187, 0.65)',
  '--color-luckyYellow': 'rgb(255, 222, 57)',
  '--color-luckyYellow--8': 'rgba(255, 222, 57, 0.8)',
  '--color-luckyBoardBg': 'rgba(24, 14, 41, 0.8)',
  '--rw-body-bg': '#1B1724',
  '--rw-default-text': '#BFBFBF',
  '--rw-highlighted-text': '#E1DFDF',
  '--rw-white-text': '#FFFFFF',
  '--rw-gray-text': '#D9D9D9',
  '--rw-purple-bg': '131, 91, 155',
  '--rw-purple-bg-border': '#493D55',
  '--rw-dark-purple-bg': '62, 43, 77',
  '--rw-light-purple-text': '#B952D9',
  '--rw-highlighted-purple': '212, 173, 244',
  '--rw-light-green-text': '164, 195, 97',
  '--rw-electric-green-text': '142, 199, 20',
  '--rw-light-green-border': '#D7E4B9',
  '--royal-win-dark': 'rgba(37, 80, 73, 1)',
  '--royal-win-dark-bg': 'rgba(39, 60, 66, 1)',
  '--royal-win-light': 'rgba(105, 185, 122, 1)',
  '--royal-win-light-1': 'rgba(116, 250, 166, 1)',
  '--rw-popup-bg': '34, 35, 42, 0.7',
  '--rw-gray-dark': '#707070',
  '--rw-purple-text': '#B952D9',
  '--rw-text-green': '#97D610',
  '--rw-text-green-dark': '#9BC441',
  '--rw-text-gray': '#9F9F9F',
  '--accept-cookies-border': 'rgb(135, 63, 162)',
  '--accept-cookies-background': 'rgb(51, 47, 63)',
  '--hotEvents-white': 'rgb(255, 255, 255)',
  '--hotEvents-gray': 'rgb(167, 169, 173)',
  '--hotEvents-black': 'rgb(9, 9, 12)',
  '--hotEvents-green': 'rgb(105, 185, 121)',
  '--hotEvents-bgc': 'rgb(35, 41, 51)',
  '--hotEvents-betMarkets': 'rgb(63, 66, 75)',
  '--color-promotion-background': 'rgb(3, 13, 24)',
  '--color-text': 'rgb(255, 255, 255)',
  '--color-text-7': 'rgba(255, 255, 255, 0.7)',
  '--color-text-6': 'rgba(255, 255, 255, 0.6)',
  '--color-text-5': 'rgba(255, 255, 255, 0.5)',
  '--color-text2': 'rgb(222, 223, 225)',
  '--color-text2-8': 'rgb(222, 223, 225, 0.8)',
  '--color-text3-6': 'rgba(221, 225, 228, 0.6)',
  '--color-text4': 'rgb(208, 177, 94)',
  '--color-text4-8': 'rgb(208, 177, 94, 0.8)',
  '--color-gold': 'rgb(221, 198, 139)',
  '--color-gray': 'rgb(141, 141, 141)',
  '--color-text3': 'rgb(207, 207, 207)',
  '--color-active': 'rgb(238, 184, 108)',
  '--color-promotion-green': 'rgb(160, 204, 65)',
  '--color-promotion-green-6': 'rgba(160, 204, 65, 0.6)',
  '--color-promotion-green2': 'rgb(161, 255, 149)',
  '--color-promotion-green2-1': 'rgba(161, 255, 149, 0.11)',
  '--color-promotion-green3-2': 'rgba(79, 124, 88, 0.2)',
  '--color-promotion-green3-1': 'rgba(79, 124, 88, 0.1)',
  '--color-promotion-green4': 'rgb(161, 205, 70)',
  '--cancel-popup-background': '255, 255, 255',
  '--cancel-popup-title': '0, 0, 0',
  '--cancel-popup-subtitle': '0, 26, 56',
  '--cancle-popup-button': '250, 209, 19',
  '--landing-gray': 'rgb(172, 169, 176)',
  '--landing-dark-gray': 'rgb(69, 64, 79)',
  '--landing-orange': 'rgb(245, 184, 64)',
  '--landing-background': 'rgb(33, 27, 45)',
  '--landing-light-background': '#342E40',
  '--calendar-background': 'rgb(33, 27, 45)',
  '--landing-background-055': 'rgba(33, 27, 45, .55)',
  '--landing-black': 'rgb(48, 42, 59)',
  '--landing-black_4': 'rgba(48, 42, 59, 0.4)',
  '--landing-green': 'rgb(105, 185, 121)',
  '--landing-green-6': 'rgba(105, 185, 121, 0.6)',
  '--landing-dark-green': 'rgb(53, 93, 61)',
  '--landing-dark-green-6': 'rgba(53, 93, 61, 0.6)',
  '--landing-light-gray': 'rgb(165, 162, 172)',
  '--landing-white': 'rgb(241, 241, 241)',
  '--footer-black': 'rgb(20, 16, 27)',
  '--footer-gray': 'rgb(245, 245, 245)',
  '--footer-text-color': 'rgb(229, 229, 229)',
  '--landing-filter-green':
    'invert(70%) sepia(12%) saturate(1459%) hue-rotate(80deg) brightness(93%) contrast(82%)',
  '--landing-filter-white':
    'invert(100%) sepia(0%) saturate(5298%) hue-rotate(218deg) brightness(117%) contrast(101%)',
  '--pmb-main-bgc1': 'rgb(48, 42, 59)',
  '--pmb-main-bgc2': 'rgb(33, 27, 45)',
  '--pmb-main-bgc3': 'rgb(69, 64, 79)',
  '--pmb-main-txt1': 'rgb(206, 206, 206)',
  '--pmb-main-txt2': 'rgb(172, 169, 176)',
  '--pmb-border-color': 'rgb(6, 5, 8)',
  '--pmb-item-border-color': 'rgb(8, 9, 13)',
  '--pmbet-white-darker': 'rgb(238, 238, 238)',
  '--pmbet-white-6': 'hsla(0, 0%, 100%, .6)',
  '--pmbet-white-5': 'hsla(0, 0%, 100%, .5)',
  '--pmbet-green': '#69B979',
  '--pmbet-white': '#fff',
  '--pmbet-orange': '#FFB300',
  '--pmbet-white-light--2': '#F1F1F1',
  '--pmbet-green-brand': 'rgb(105, 185, 121)',
  '--pmb-separator': '#7D7A81',
  '--pmb-sp-txt': '#F5F5F5',
  '--pmb-favorite-yellow': '#F4ED27',
  '--pmb-dark-green': '#355D3D',
  '--pmb-gray': '#45404F',
  '--pmb-gray-dark': '#302A3B',
  '--pmb-market-info-txt': '#DBDBDB',
  '--pmb-txt-E': '#EEEEEE',
  '--pmb-txt-gray': '#BDBBC0',
  '--casino-gray': 'rgb(174, 171, 178)',
  '--casino-ligth-gray': 'rgb(75, 68, 88)',
  '--casino-ligth-white': 'rgb(236, 236, 236)',
  '--casino-black': 'rgb(27, 23, 34)',
  '--casino-text-color': 'rgb(180, 178, 184)',
  '--sport-league-background': 'rgba(105, 185, 121, 1)',
  '--wex-dark-silver': '53, 69, 82',
  '--wex-footer-button': '83, 107, 128',
  '--wex-bet-button-mobile': '16, 16, 16',
  '--wex-left-menu-header-mobile': '33, 44, 53',
  '--wex-left-right-menu-background-mobile': '45, 39, 31',
  '--wex-header-text-green': '170, 173, 180',
  '--wex-item-border-white': '0, 0, 0',
  '--wex-left-menu-background': '28, 35, 41',
  '--wex-left-menu-background-empty': '34, 41, 51',
  '--jackpot-button-bg': '39, 39, 39 !important',
  '--jackpot-button-active-bg': '39, 39, 39, 0.7 !important',
  '--active-button-bg': '0, 62, 71 !important',
  '--sport-league-background-hover': 'rgba(255, 241, 0, 0.4)',
  '--sports-single-background': 'rgb(43, 43, 43)',
  '--wex-input-silver': '211, 211, 211',
  '--wex-blue': '0, 88, 194',
  '--wex-button-green': '0, 199, 122',
  '--wex-dark-green': '15, 38, 44',
  '--wex-background-dark-green': '25, 58, 58',
  '--wex-light-1': 'rgba(116, 250, 166, 1)',
  '--wex-danger': 'rgb(255,0,0)',
  '--wex-ussd-step-title': 'rgb(13, 118, 80)',
  '--wex-multicaixa': 'rgb(0, 33, 51)',
  '--wex-ussd-title': '0, 128, 0',
  '--wex-green-2': '#86C1AF',
  '--wex-gradient-1': '#245148',
  '--wex-gradient-2': '#0F262C',
  '--wex-header-gradient': '#06262D',
  '--wex-button-gradient-1': '#00C77A',
  '--wex-button-gradient-2': '#1B4334',
  '--pmb-txt-CE': '#CECECE',
  '--Gwin-tawk-header-background-color': '#00FF9D !important',
  '--casino-tab-background': 'rgb(11, 31, 36)',
  '--mexico-white': '255, 255, 255',
  '--wex-pages-background': '47, 61, 84',
  '--wex-silver-border': '163, 163, 163',
  '--wex-input-disabled': '234, 234,234',
  '--wex-lightgray-scroll-track': '203, 203, 203',
  '--vamos-blue': 'rgb(3, 41, 95)',
  '--vamos-blue-light': '#000A38',
  '--vamos-yellow': '#DCA73D',
};
