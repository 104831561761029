/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchColorConfigsThunks = createAsyncThunk(
  'colorConfigs/fetchColorConfigsThunks',
  async (data: string = '', thunkAPI) => {
    try {
      const response = await axios.post('/configs/get-project-configs', {
        data,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateColorConfig = createAsyncThunk(
  'colorConfigs/updateColorConfig',
  async (data: string = '', thunkAPI) => {
    try {
      const response = await axios.post('/configs/update-project-configs', {
        config: {
          websiteTheme: data,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
