/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Table, Button, ColorPicker, Pagination } from 'antd';
import type { Color } from 'antd/es/color-picker';
import type { ColumnsType } from 'antd/es/table';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store.types';
import {
  fetchColorConfigsThunks,
  updateColorConfig,
} from 'redux/reducers/colorConfigs/colorConfigs.thunks';
import { colors } from './config';
import { fetchProjectList } from 'redux/reducers/projects/project.thunks';

export const parseCSSVariables = (data: string): any => {
  return data
    .replace(/[{}]/g, '')
    .split(';')
    .map(item => item.trim())
    .filter(item => item)
    .reduce<any>((acc, item) => {
      let [key, value] = item.split(':').map(s => s.trim());

      key = key.replace(/^----/, '--');

      if (value.startsWith('rgb(')) {
        value = value.replace(/rgb\(|\)/g, '');
      }

      acc[key] = value;
      return acc;
    }, {});
};

type CSSVariable = {
  key: string;
  name: string;
  value: string;
};

const ColorConfigs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { colorsConfigs, loading } = useSelector(
    (state: RootState) => state.colorConfigsSlice,
  );

  const [colorData, setColorData] = useState<CSSVariable[]>(
    colorsConfigs ||
      Object.keys(colors).map(key => ({
        key,
        name: key,
        value: `rgb(${(colors as { [key: string]: string })[key]})`,
      })),
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    dispatch(fetchProjectList());
    dispatch(fetchColorConfigsThunks(''));
  }, [dispatch]);

  useEffect(() => {
    if (colorsConfigs) {
      const parsedColors = Object.keys(colorsConfigs).map(key => ({
        key,
        name: key,
        value: `rgb(${(colorsConfigs as { [key: string]: string })[key]})`,
      }));
      setColorData(parsedColors);
      setTotalItems(parsedColors.length);
    } else {
      const parsedColors = Object.keys(colors).map(key => ({
        key,
        name: key,
        value: `rgb(${(colors as { [key: string]: string })[key]})`,
      }));
      setColorData(parsedColors);
      setTotalItems(parsedColors.length);
    }
  }, [colorsConfigs]);

  const handleColorChange = (key: string, color: Color) => {
    const newColor = color.toRgbString();
    setColorData((prevData: CSSVariable[]) =>
      prevData.map((item: CSSVariable) =>
        item.key === key ? { ...item, value: newColor } : item,
      ),
    );
  };

  const generateCSSString = async () => {
    try {
      const updatedString =
        '{' +
        colorData
          .map((item: CSSVariable) => {
            const isRGB = /^rgb\(\d+,\s*\d+,\s*\d+\)$/i.test(item.value);
            const containsHex = /#([0-9A-Fa-f]{3,6})/.test(item.value);

            if (isRGB) {
              return `${item.key}: ${item.value};`;
            } else if (containsHex) {
              return `${item.key}: ${
                item.value.match(/#([0-9A-Fa-f]{3,6})/)![0]
              };`;
            } else {
              return `${item.key}: ${item.value};`;
            }
          })
          .join(' ') +
        '}';

      // const jsonString = JSON.stringify(updatedString, null, 2);
      // console.log(updatedString);

      await dispatch(updateColorConfig(updatedString));
      dispatch(fetchColorConfigsThunks(''));
      setCurrentPage(1);
    } catch (error) {
      console.error('Error dispatching thunks:', error);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns: ColumnsType<CSSVariable> = [
    {
      title: 'Variable Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Color Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Edit Color',
      dataIndex: 'value',
      key: 'edit',
      render: (color: string, record: CSSVariable) => (
        <ColorPicker
          value={color}
          format='rgb'
          onChange={value => handleColorChange(record.key, value as Color)}
        />
      ),
    },
    {
      title: 'Color Preview',
      dataIndex: 'value',
      key: 'preview',
      render: (color: string) => (
        <div
          style={{
            width: '50px',
            height: '20px',
            backgroundColor: color,
            border: '1px solid #ccc',
          }}
        />
      ),
    },
  ];
  return (
    <div>
      {Array.isArray(colorData) && colorData.length > 0 ? (
        <>
          <Table
            dataSource={colorData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
            )}
            columns={columns}
            pagination={false}
            rowKey='key'
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalItems}
            onChange={handlePaginationChange}
            style={{ marginTop: 16 }}
          />
        </>
      ) : (
        <p>No data available</p>
      )}
      <Button
        type='primary'
        onClick={generateCSSString}
        style={{ marginTop: 10 }}>
        Save
      </Button>
    </div>
  );
};

export default ColorConfigs;
