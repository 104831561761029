/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { clearFilters, removeGameFromTag, removeTag } from './tags.slice';

import { setError } from './tags.slice';
import axios from 'axios';

interface CreateTagData {
  name: string;
  priority: number;
}

export const fetchTags = createAsyncThunk(
  'tags/fetchTags',
  async ({ params = {}, body = {} }: { params: any; body: any }, thunkAPI) => {
    try {
      const queryParams = {
        sort: params.sort || 'id',
        order: params.order || 'asc',
        page: params.page || 1,
        limit: params.limit || 1,
      };

      const response = await axios.post(
        `/casino/tags?sort=${queryParams.sort}&order=${queryParams.order}&page=${queryParams.page}&limit=${queryParams.limit}`,
        { filters: { ...body } },
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const createTagThunk = createAsyncThunk(
  'tags/createTag',
  async (tagData: CreateTagData, thunkAPI) => {
    try {
      const response = await axios.post('/casino/tag', {
        name: tagData.name,
        priority: tagData.priority,
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          'Failed to create tag',
      );
    }
  },
);

export const updateTagThunk = createAsyncThunk(
  'tags/updateTagThunk',
  async (
    tag: { id: string; name: string; priority: number; deleted: boolean },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.put(`/casino/tag/${tag.id}`, {
        name: tag.name,
        priority: tag.priority,
        deleted: tag.deleted,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);
export const deleteTagThunk = createAsyncThunk(
  'tags/deleteTag',
  async (tagIds: string[], { rejectWithValue, dispatch }) => {
    try {
      await axios.delete('/casino/tag', {
        data: {
          ids: tagIds,
        },
      });
      dispatch(removeTag(tagIds[0]));
      return tagIds;
    } catch (error) {
      return rejectWithValue('Failed to delete tag');
    }
  },
);

export const deleteGameFromTagThunk = createAsyncThunk(
  'tags/deleteGameFromTagThunk',
  async (
    { tagId, gameId }: { tagId: any; gameId: any },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await axios.delete(`/casino/tags/${tagId}/games/${gameId}`);
      dispatch(removeGameFromTag(gameId));

      return tagId;
    } catch (error) {
      return rejectWithValue('Failed to delete tag');
    }
  },
);

// export const searchGamesThunk = createAsyncThunk(
//   'tags/searchGamesThunk',
//   async (keyword: string, { dispatch, rejectWithValue }) => {
//     try {
//       const response = await axios.post('casino/games/search', {
//         keyword,
//       });
//       return response.data.data;
//     } catch (error) {
//       dispatch(
//         setError(error instanceof Error ? error.message : 'An error occurred'),
//       );
//       return rejectWithValue(error);
//     }
//   },
// );
export const searchGamesThunk = createAsyncThunk(
  'tags/searchGamesThunk',
  async (keyword: string, { dispatch, rejectWithValue }) => {
    // Check if the keyword is an empty string or just spaces
    if (!keyword.trim()) {
      // Dispatch an action to reset or clear filters if keyword is empty
      dispatch(clearFilters()); // Assuming you have a clearFilters action to reset filters
      return; // Don't make an API call
    }

    try {
      const response = await axios.post('casino/games/search', { keyword });
      return response.data.data;
    } catch (error) {
      dispatch(
        setError(error instanceof Error ? error.message : 'An error occurred'),
      );
      return rejectWithValue(error);
    }
  },
);

export const getTagGamesThunk = createAsyncThunk(
  'tags/getTagGamesThunk',
  async ({ tagId, page }: { tagId: any; page: any }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/casino/tag/${tagId}/games?sort=${'name'}&order=${'asc'}&limit=${20}&page=${page}`,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const addGamesTagThunk = createAsyncThunk(
  'tags/addGamesTagThunk',
  async (
    { tagId, gameIds }: { tagId: any; gameIds: any },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(`/casino/tag/games/${tagId}`, {
        gameIds,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
