import dayjs from 'dayjs';

const statusConfig: any = {
  pending: '#AFAFB4',
  success: '#73d13d',
  rejected: '#FF4D2F',
  deleted: '#D32F2F',
  expire: '#FFCC00',
  canceled: '#6DA8DB',
};

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Game Id',
    dataIndex: 'gameId',
    key: 'gameId',
  },
  {
    title: 'Name',
    dataIndex: 'gameName',
    key: 'name',
  },
  {
    title: 'Aggregator',
    dataIndex: 'aggregator',
    key: 'aggregator',
  },
  {
    title: 'Player Id',
    dataIndex: 'playerId',
    key: 'playerId',
    sorter: true,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Denomination',
    dataIndex: 'denomination',
    key: 'denomination',
  },
  {
    title: 'Bet Id',
    dataIndex: 'betId',
    key: 'betId',
  },

  {
    title: 'Valid From',
    dataIndex: 'validFrom',
    key: 'validFrom',
    render: (text: string) => dayjs(text).format('MMM D, YYYY HH:mm'),
    sorter: true,
  },

  {
    title: 'Valid Until',
    dataIndex: 'validUntil',
    key: 'validUntil',
    render: (text: string) => dayjs(text).format('MMM D, YYYY HH:mm'),
  },

  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => dayjs(text).format('MMM D, YYYY HH:mm'),
  },

  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (text: string) => dayjs(text).format('MMM D, YYYY HH:mm'),
  },
  {
    title: 'Bet Level',
    dataIndex: 'betLevel',
    key: 'betLevel',
  },
  {
    title: 'Campaign',
    dataIndex: 'campaignName',
    key: 'campaignName',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      const statusKey = status.toLowerCase();
      const color = statusConfig[statusKey] || '#ccc';

      return (
        <div
          style={{
            backgroundColor: color,
            padding: '6px 10px',
            borderRadius: '5px',
            marginRight: '8px',
            textAlign: 'center',
          }}>
          {status}
        </div>
      );
    },
  },
];
