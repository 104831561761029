/* eslint-disable */
import {
  deletedBy,
  deviecSupportConfigs,
} from '../Casino/Games/gamesConfig/generalGamesTable.config';

export const columns = [
  {
    title: 'Game Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Aggregator',
    dataIndex: 'aggregator',
    key: 'aggregator',
  },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
    render: (text: boolean) => (text ? 'Visible' : 'Hidden'),
  },
  {
    title: 'Device Support',
    dataIndex: 'deviceSupport',
    key: 'deviceSupport',
    render: (text: number) => {
      return deviecSupportConfigs[text];
    },
  },
  {
    title: 'Deleted',
    dataIndex: 'deleted',
    key: 'deleted',
    render: (text: number) => {
      return deletedBy[text];
    },
  },
  { title: 'Site Section', dataIndex: 'siteSection', key: 'siteSection' },
];
