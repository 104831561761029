/* eslint-disable */
import { Modal, Form, Input, InputNumber, Switch } from 'antd';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateTag } from 'redux/reducers/tags/tags.slice';
import { updateTagThunk } from 'redux/reducers/tags/tags.thunks';
import { TagData } from 'redux/reducers/tags/types';

interface EditTagModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onOk: (tag: TagData) => void;
  tag: TagData | null;
  isCreateMode: boolean;
}

const EditTagModal: FC<EditTagModalProps> = ({
  isVisible,
  onCancel,
  onOk,
  tag,
  isCreateMode = false,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible) {
      if (isCreateMode) {
        form.resetFields();
        form.setFieldsValue({
          name: '',
          priority: 0,
          deleted: false,
        });
      } else if (tag) {
        form.setFieldsValue({
          name: tag.name,
          priority: tag.priority,
          deleted: tag.deleted,
        });
      }
    }
  }, [isVisible, tag, isCreateMode, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (isCreateMode) {
        const newTag: any = {
          id: values.id,
          name: values.name,
          priority: values.priority || 0,
          deleted: values.deleted || false,
          gamesList: [],
        };
        onOk(newTag);
      } else {
        if (!tag) {
          console.error('No tag provided for update');
          return;
        }

        onOk({
          ...tag,
          ...values,
        });
      }
      dispatch(
        updateTag({
          id: tag?.id,
          name: values.name,
          priority: values.priority,
          deleted: values.deleted,
        }),
      );
    } catch (error) {
      console.error('Validation or API request failed:', error);
    }
  };

  return (
    <Modal
      title={isCreateMode ? 'Create Tag' : 'Edit Tag'}
      open={isVisible}
      onCancel={onCancel}
      onOk={handleSubmit}>
      <Form form={form} layout='vertical'>
        <Form.Item
          name='name'
          label='Name'
          rules={[{ required: true, message: 'Please input tag name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='priority'
          label='Priority'
          rules={[{ required: true, message: 'Please input priority!' }]}>
          <InputNumber min={0} />
        </Form.Item>
        {!isCreateMode && (
          <Form.Item name='deleted' label='Deleted' valuePropName='checked'>
            <Switch />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default EditTagModal;
