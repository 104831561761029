/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Upload,
  Image,
  Select,
  message,
  ColorPicker,
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  readOnlymodalConfigForChange,
  selectConfigs,
  singleChoicemodalConfig,
} from '../gamesConfig/generalGamesTable.config';
import {
  modalConfig,
  readOnlymodalConfig,
} from '../gamesConfig/generalGamesTable.config';

import styles from './Games.module.scss';

import type { ColorPickerProps, UploadFile, UploadProps } from 'antd';
import { BASE_URL } from 'api/axios';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { RootState } from 'redux/store.types';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { GameData } from 'redux/reducers/casino/games/games.types';

interface EditGameModalProps {
  visible: boolean;
  editingGame: any;
  setEditingGame: (game: any) => void;
  handleOk: () => void;
  handleCancel: () => void;
}

const EditGameModal: React.FC<EditGameModalProps> = ({
  visible,
  // editingGame,
  setEditingGame,
  handleOk,
  handleCancel,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [canUpdateImage, setCanUpdateImage] = useState(false);
  const authInfo = useSelector(selectAuthUserInfo);
  const { currentGame, loading } = useSelector(
    (state: RootState) => state.games,
  );
  const [previewImage, setPreviewImage] = useState(currentGame?.image || '');
  const [localGame, setLocalGame] = useState<GameData>({});

  useEffect(() => {
    if (currentGame?.image) {
      const imageUrl = currentGame.image;
      setFileList([
        {
          uid: '-1',
          name: imageUrl,
          status: 'done',
          url: imageUrl,
        },
      ]);
      setPreviewImage(imageUrl);
    }
    setLocalGame(currentGame);
  }, [currentGame, visible]);

  useEffect(() => {
    const updatedFields: Partial<GameData> = {};

    Object.keys(localGame).forEach(key => {
      if (localGame[key] !== currentGame?.[key]) {
        updatedFields[key] = localGame[key];
      }
    });

    setEditingGame(updatedFields);
  }, [localGame]);

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0]?.originFileObj) {
      const newUpladed = URL.createObjectURL(newFileList[0].originFileObj);
      setLocalGame((prev: any) => ({
        ...prev,
        image: newUpladed,
      }));
    } else {
      setLocalGame((prev: any) => ({
        ...prev,
        image: '',
      }));
    }
  };

  const fields = localGame ? Object.keys(localGame) : [];
  const filteredFields = fields.filter(
    item =>
      !['id', 'uuid', 'image', 'status', 'createdAt', 'updatedAt'].includes(
        item,
      ),
  );

  const handleColorChange = (color: string) => {
    setLocalGame((prev: any) => ({
      ...prev,
      markerType: color,
    }));
  };

  return (
    <Modal
      title={<span className={styles.modalTitle}>Edit Game</span>}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}>
      <>
        {loading ? (
          <GlobalLoader />
        ) : (
          <div className={styles.modalContent}>
            {localGame && (
              <div className={styles.formContainer}>
                <Form>
                  {filteredFields.map((item: string) => {
                    const value = localGame[item];

                    return (
                      <div className={styles.fieldGroup} key={item}>
                        <span className={styles.label}>
                          {modalConfig[item]}
                        </span>
                        <Form.Item
                          className={styles.inputField}
                          validateStatus={
                            item === 'priority' &&
                            (Number(value) < 1 ||
                              Number(value) > 10000 ||
                              !Number.isInteger(Number(value)))
                              ? 'error'
                              : item === 'markerText' &&
                                typeof value === 'string' &&
                                value.length > 15
                              ? 'error'
                              : ''
                          }
                          help={
                            (item === 'priority' &&
                              (Number(value) < 1 ||
                                Number(value) > 10000 ||
                                !Number.isInteger(Number(value))) && (
                                <div
                                  style={{
                                    margin: 3,
                                    fontSize: '11.3px',
                                    lineHeight: '1.5',
                                  }}>
                                  Please, enter numbers from 1 to 10.000 for
                                  Priority
                                </div>
                              )) ||
                            (item === 'markerText' &&
                              typeof value === 'string' &&
                              value.length > 15 && (
                                <div
                                  style={{
                                    margin: 3,
                                    fontSize: '11.3px',
                                    lineHeight: '1.5',
                                  }}>
                                  The maximum allowed number of characters is 15
                                </div>
                              ))
                          }>
                          {singleChoicemodalConfig.includes(item) ? (
                            <Select
                              value={
                                item === 'visibility'
                                  ? String(value)
                                  : singleChoicemodalConfig.includes(item) &&
                                    selectConfigs[item]
                                  ? selectConfigs[item][value] || ''
                                  : ''
                              }
                              onChange={newValue => {
                                setLocalGame({
                                  ...localGame,
                                  [item]:
                                    item === 'visibility'
                                      ? newValue === 'true'
                                      : newValue,
                                });
                              }}
                              disabled={readOnlymodalConfig.includes(item)}>
                              {selectConfigs[item] &&
                                Object.entries(
                                  selectConfigs[item] as Record<string, string>,
                                ).map(([key, label]) => (
                                  <Select.Option key={key} value={key}>
                                    {label}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : item === 'markerType' ? (
                            <ColorPicker
                              value={localGame.markerType || '#1677ff'}
                              onChange={color => {
                                const hexColor = color.toHexString();
                                handleColorChange(hexColor);
                              }}
                              showText
                              format='hex'
                            />
                          ) : readOnlymodalConfigForChange[item] ? (
                            <Input
                              value={
                                readOnlymodalConfigForChange[
                                  item as keyof typeof readOnlymodalConfigForChange
                                ]?.[value] || ''
                              }
                              disabled
                              style={{ backgroundColor: '#f5f5f5' }}
                            />
                          ) : (
                            <Input
                              value={value}
                              onChange={e => {
                                const newValue = e.target.value;

                                setLocalGame({
                                  ...localGame,
                                  [item]: newValue,
                                });
                              }}
                              onBlur={() => {}}
                              disabled={readOnlymodalConfig.includes(item)}
                              style={
                                item === 'priority' && value !== undefined
                                  ? {
                                      borderColor:
                                        Number(value) >= 1 &&
                                        Number(value) <= 10000
                                          ? ''
                                          : 'red',
                                    }
                                  : {}
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    );
                  })}

                  <div className={styles.fieldGroup}>
                    <span className={styles.label}>Image</span>
                    <Form.Item className={styles.inputField}>
                      <Upload
                        name='image'
                        action=''
                        listType='picture-card'
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        method='PUT'
                        headers={{
                          'X-Auth-Token': authInfo.token,
                        }}
                        beforeUpload={async file => {
                          const isImage = file.type.startsWith('image/');
                          if (!isImage) {
                            message.error('You can only upload image format!');
                            return false;
                          }

                          return false;
                        }}>
                        {fileList.length >= 1 ? null : (
                          <div>
                            <PlusOutlined />

                            <div
                              style={{
                                margin: 3,
                                fontSize: '11.5px',
                                lineHeight: '1.5',
                              }}>
                              Upload Picture
                            </div>
                          </div>
                        )}
                      </Upload>
                      {localGame.image && (
                        <>
                          <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: item => setPreviewOpen(item),
                            }}
                            src={localGame.image}
                          />
                          {canUpdateImage && (
                            <Button
                              type='primary'
                              onClick={() => {
                                setFileList([]);
                                setLocalGame({
                                  ...localGame,
                                  image: '',
                                });
                                setCanUpdateImage(false);
                              }}>
                              Update Image
                            </Button>
                          )}
                        </>
                      )}
                      <div className={styles.recommendedSize}>
                        Recommended size - 366 x 213 px
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            )}
          </div>
        )}
      </>
    </Modal>
  );
};

export default EditGameModal;
