/* eslint-disable */
import React, { useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  message,
} from 'antd';
import { useAppDispatch } from 'redux/hooks/redux.hooks';
import FreeSpinsTable from './FreeSpinsTable';
import classes from './ManualAndAutomatized.module.scss';
import { freeSpinsFilterThunks } from 'redux/reducers/freeSpins/freeSpins.thunks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ManualAndAutomatized: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState<{
    gameName: string;
    createdAt: [any, any];
    status: string;
  }>({
    gameName: '',
    createdAt: ['', ''],
    status: '',
  });

  const dispatch = useAppDispatch();

  const handleMenuClick = (e: { key: string }) => {
    setFormData(prev => ({
      ...prev,
      status: e.key !== 'Selecet All' ? e.key : '',
    }));
  };

  const statusOptions = [
    'Selecet All',
    'pending',
    'rejected',
    'success',
    'canceled',
    'deleted',
    'expired',
  ];

  const menu = (
    <Menu onClick={handleMenuClick}>
      {statusOptions.map(status => (
        <Menu.Item key={status}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Menu.Item>
      ))}
    </Menu>
  );

  const cleanFilters = (
    filtersData: Record<string, any>,
  ): Record<string, any> => {
    return Object.entries(filtersData).reduce((acc, [key, value]) => {
      if (
        value === '' ||
        (key === 'createdAt' &&
          Array.isArray(value) &&
          value.every(item => item === ''))
      ) {
        return acc;
      }
      acc[key] = value;
      return acc;
    }, {} as Record<string, any>);
  };

  const handleSearch = () => {
    if (
      formData.createdAt[0] &&
      formData.createdAt[1] &&
      formData.createdAt[1].isBefore(formData.createdAt[0])
    ) {
      message.warning('Date From cannot be later than Date To.');
      return;
    }
    dispatch(
      freeSpinsFilterThunks({
        addProps: {},
        body: formData,
      }),
    );
    setCurrentPage(1);
  };

  return (
    <div className={classes.container}>
      <Row gutter={5} style={{ marginBottom: '16px' }}>
        <Col span={5}>
          Date From
          {/* <DatePicker
            style={{ width: '100%' }}
            showTime
            placeholder='From Date'
            value={
              formData.createdAt[0]
                ? dayjs(formData.createdAt[0]).local()
                : null
            }
            onChange={date =>
              setFormData(prev => ({
                ...prev,
                createdAt: [date ? dayjs(date) : '', prev.createdAt[1]],
              }))
            }
          /> */}
          <DatePicker
            style={{ width: '100%' }}
            showTime
            placeholder='From Date'
            value={
              formData.createdAt[0]
                ? dayjs(formData.createdAt[0]).tz(dayjs.tz.guess())
                : null
            }
            onChange={date =>
              setFormData(prev => ({
                ...prev,
                createdAt: [
                  date ? dayjs(date).tz(dayjs.tz.guess(), true) : '',
                  prev.createdAt[1],
                ],
              }))
            }
          />
        </Col>
        <Col span={5}>
          Date To
          {/* <DatePicker
            style={{ width: '100%' }}
            showTime
            placeholder='To Date'
            value={
              formData.createdAt[1]
                ? dayjs(formData.createdAt[1]).local()
                : null
            }
            onChange={date =>
              setFormData(prev => ({
                ...prev,
                createdAt: [prev.createdAt[0], date ? dayjs(date) : ''],
              }))
            }
          /> */}
          <DatePicker
            style={{ width: '100%' }}
            showTime
            placeholder='To Date'
            value={
              formData.createdAt[1]
                ? dayjs(formData.createdAt[1]).tz(dayjs.tz.guess())
                : null
            }
            onChange={date =>
              setFormData(prev => ({
                ...prev,
                createdAt: [
                  prev.createdAt[0],
                  date ? dayjs(date).tz(dayjs.tz.guess(), true) : '',
                ],
              }))
            }
          />
        </Col>
      </Row>
      <Row gutter={5}>
        <Col span={5}>
          <Input
            style={{ width: '100%' }}
            placeholder='Search by Name'
            allowClear
            value={formData.gameName}
            onChange={e =>
              setFormData(prev => ({ ...prev, gameName: e.target.value }))
            }
          />
        </Col>
        <Col span={5}>
          <Dropdown
            overlayStyle={{ textAlign: 'center' }}
            overlay={menu}
            trigger={['click']}>
            <Button style={{ width: '100%' }} type='default'>
              {formData.status.charAt(0).toUpperCase() +
                formData.status.slice(1) || 'Select All'}
            </Button>
          </Dropdown>
        </Col>
        <Col span={5}>
          <Button style={{ width: '50%' }} onClick={handleSearch}>
            Search
          </Button>
        </Col>
      </Row>
      <div className={classes.tableSection}>
        <FreeSpinsTable
          filtersData={formData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ManualAndAutomatized;
