import React, { useState } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';

import { fetchAggregatorsThunks } from 'redux/reducers/casino/aggregators/aggregators.thunks';
import { Aggregator } from 'redux/reducers/casino/aggregators/aggregators.types';
import { useAppDispatch } from 'redux/hooks/redux.hooks';

import classes from './Aggregators.module.scss';

const AgreggatorsFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedAggregators, setSelectedAggregators] = useState<Aggregator[]>(
    [],
  );
  const [accordionAggregators, setAccordionAggregators] = useState<
    Aggregator[]
  >([]);
  const { aggregators } = useSelector((state: any) => state.aggregators);

  const handleSelectedAggregator = (item: Aggregator) => {
    setAccordionAggregators(accordionAggregators);
    setSelectedAggregators(prevArray => {
      const isSelected = prevArray?.some(
        selectedItem => selectedItem.name === item.name,
      );
      if (isSelected) {
        return prevArray.filter(
          selectedItem => selectedItem.name !== item.name,
        );
      }
      return [...prevArray, item];
    });
  };

  const handleSearch = () => {
    setAccordionAggregators(selectedAggregators);
    dispatch(fetchAggregatorsThunks(selectedAggregators));
    setSelectedAggregators([]);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.form}>
          <h2 className={classes.titleAggregator}>Aggregators</h2>
          {aggregators?.map((item: Aggregator) => (
            <Button
              onClick={() => handleSelectedAggregator(item)}
              key={item.name}
              type={
                selectedAggregators?.some(
                  selectedItem => selectedItem.name === item.name,
                )
                  ? 'primary'
                  : 'default'
              }
              style={{ margin: '5px' }}>
              {item.name}
            </Button>
          ))}
        </div>

        <div className={classes.searchBtn}>
          <Button onClick={handleSearch} className={classes.btn} type='primary'>
            Show Games
          </Button>
        </div>
      </div>
    </>
  );
};

export default AgreggatorsFilters;
