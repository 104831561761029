/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchGameById,
  filterGamesThunk,
  searchGamesThunk,
} from './games.thunks';

const initialState: any = {
  allGames: [],
  gameCount: 0,
  loading: false,
  error: null,
  currentGame: {},
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    updateGame: (state, action: PayloadAction<any>) => {
      const { id, ...updatedFields } = action.payload;
      const index = state.allGames.findIndex((game: any) => game.id === id);

      if (index !== -1) {
        state.allGames[index] = {
          ...state.allGames[index],
          ...updatedFields,
        };
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(searchGamesThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchGamesThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allGames = action.payload.data;
        },
      )
      .addCase(searchGamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch general games';
      })
      .addCase(filterGamesThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        filterGamesThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allGames = action.payload.dataList;
          state.gameCount = action.payload.count;
        },
      )
      .addCase(filterGamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || 'Failed to filter games';
      })
      .addCase(fetchGameById.pending, state => {
        state.loading = true;
        state.error = null;
      });
    builder.addCase(fetchGameById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentGame = action.payload.data[0];
    });
    builder.addCase(fetchGameById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default gamesSlice.reducer;
export const { updateGame } = gamesSlice.actions;
