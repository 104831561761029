/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const removeEmptyKeys = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== ''),
  );
};

export const freeSpinsFilterThunks = createAsyncThunk(
  'freeSpins/freeSpinsFilterThunks',
  async (
    { addProps, body }: { addProps: any; body: any },
    { rejectWithValue },
  ) => {
    try {
      console.log(body);

      if (Object.keys(body).length > 0 && Array.isArray(body.createdAt)) {
        if (body.createdAt.every((item: any) => item === '')) {
          body.createdAt = [];
        } else {
          body.createdAt = body.createdAt.map((item: any) =>
            item == null ? '' : item,
          );
        }
      }

      const cleanedBody = removeEmptyKeys(body);
      const queryParams = [
        addProps.sort ? `sort=${addProps.sort}` : null,
        addProps.pageSize ? `limit=${addProps.pageSize}` : null,
        addProps.currentPage ? `page=${addProps.currentPage}` : null,
        addProps.order ? `order=${addProps.order}` : null,
      ]
        .filter(Boolean)
        .join('&');

      const response = await axios.post(`/casino/freespins?${queryParams}`, {
        filters: cleanedBody,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch free spins games',
      );
    }
  },
);

export const uploadExcelFileThunk = createAsyncThunk(
  'games/uploadExcelFileThunk',
  async (
    {
      file,
      quantity,
      denomination,
      betId,
      validUntil,
      gameId,
      playerId,
    }: {
      file: File | undefined;
      quantity: number;
      denomination: number;
      betId: number;
      validUntil: string;
      gameId: number;
      playerId: any;
    },
    thunkAPI,
  ) => {
    try {
      const formDataToUpload = new FormData();
      file && formDataToUpload.append('file', file);
      formDataToUpload.append('quantity', String(quantity));
      formDataToUpload.append('playerIds', playerId);
      formDataToUpload.append('denomination', String(denomination));
      formDataToUpload.append('betId', String(betId));
      formDataToUpload.append('validUntil', validUntil);
      formDataToUpload.append('gameId', String(gameId));
      const response = await axios.post(`/casino/freespin`, formDataToUpload);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const searchFreeSpinsThunk = createAsyncThunk(
  'games/searchGamesThunk',
  async (keyword: string, thunkAPI) => {
    try {
      const response = await axios.post(
        'casino/games/search?has_freespins=true',
        {
          keyword,
        },
      );
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getDenominationsAndBetsThunk = createAsyncThunk(
  'games/getDenominationsAndBets',
  async (uuid: any, thunkAPI) => {
    try {
      const response = await axios.post('casino/games/freespinBets', {
        uuid,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
