/* eslint-disable */
import { Button, Checkbox, Collapse, Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks/redux.hooks';
import { synchProvidersGamesThunks } from 'redux/reducers/casino/aggregators/aggregators.thunks';
import { filterGamesThunk } from 'redux/reducers/casino/games/games.thunks';
import GamesTable from '../../Games/components/AggregatorGamesTable';
import { selecetdDataConfig } from '../aggregatorConfig/aggregatorGamesTable.config';
import classes from './Aggregators.module.scss';
import { Aggregators } from 'redux/reducers/casino/aggregators/aggregators.types';
import { InfoCircleFilled } from '@ant-design/icons';
import img from '../../../../../assets/images/icon.jpg';

import { Tooltip } from 'antd';

const { Panel } = Collapse;

const AggregatorsAccordion: React.FC = () => {
  const selectedAggregators = useAppSelector(
    (state: any) => state.aggregators.selectedAggregators,
  );
  const [localData, setLocalData] = useState([...selectedAggregators]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocalData([...selectedAggregators]);
  }, [selectedAggregators]);

  const handleCheckboxChange = (item: any) => {
    setSelectedItems(prevItems => {
      const isAlreadySelected = prevItems.includes(item.uuid);

      if (isAlreadySelected) {
        return prevItems.filter(uuid => uuid !== item.uuid);
      } else {
        return [...prevItems, item.uuid];
      }
    });
  };

  const handleDeleteSelected = async () => {
    const keysArray = Object.keys(localData[0]);
    const payload = {
      synchGames: selectedItems,
      choosenAggregators: [...keysArray],
    };
    const resultAction = await dispatch(synchProvidersGamesThunks(payload));
    if (synchProvidersGamesThunks.fulfilled.match(resultAction)) {
      setLocalData([]);
      dispatch(
        filterGamesThunk({
          body: {},
          additionalProps: { currentPage: 1, pageSize: 20 },
        }),
      );
    } else {
      console.error('Sync failed:', resultAction.payload || 'Unknown error');
    }
  };

  const handleToggleSelectAll = (
    aggregatorName: string,
    event: React.MouseEvent,
  ) => {
    event.stopPropagation();

    const games = localData.find(aggregator => aggregatorName in aggregator);
    const allItems = games ? games[aggregatorName] : [];

    const areAllSelected = allItems.every((game: any) =>
      selectedItems.includes(game.uuid),
    );

    if (areAllSelected) {
      setSelectedItems(prevItems =>
        prevItems.filter(
          (selectedItem: any) =>
            !allItems?.some((item: any) => item.uuid === selectedItem),
        ),
      );
    } else {
      const newSelectedItems = allItems.map((game: any) => game.uuid);
      setSelectedItems(newSelectedItems);
    }
  };

  const configWithCheckbox = [
    selecetdDataConfig[0],
    {
      title: (
        <div className='deleteGameColumn'>
          Select Games to Delete
          <Tooltip
            className={classes.tooltip}
            title="After you click on the Sync button, the games which have been selected are marked as 'Deleted by Operator' status and are deleted from the list of games on your website. Meanwhile, these games will be available in the Admin Panel’s games page with the assigned status which you can then change from there."
            overlayInnerStyle={{
              color: '#333',
              fontSize: '14px',
              lineHeight: '1.2',
              width: '400px',
              textAlign: 'justify',
              padding: '10px',
              backgroundColor: '#fff',
            }}
            overlayClassName='custom-tooltip'>
            <InfoCircleFilled />
          </Tooltip>
        </div>
      ),
      dataIndex: 'checkForDelete',
      key: 'checkForDelete',
      render: (text: any, item: any) => {
        console.log(text);
        const isChecked = selectedItems.includes(item.uuid);
        return (
          <>
            {item.status !== 2 ? (
              <Checkbox
                checked={isChecked}
                onChange={() => handleCheckboxChange(item)}
              />
            ) : (
              'Already Deleted'
            )}
          </>
        );
      },
    },
    ...selecetdDataConfig.slice(1),
  ];

  return (
    <>
      {localData.length > 0 ? (
        <>
          <Collapse
            className='collapse-container'
            activeKey={activeKey}
            onChange={keys => setActiveKey(keys as string[])}>
            {localData.map((aggregators: Aggregators) =>
              Object.entries(aggregators).map(([aggregatorName, games]) => (
                <Panel
                  header={
                    <div className='panel-header'>
                      <span>{aggregatorName}</span>
                      {activeKey.includes(aggregatorName) && (
                        <Button
                          type='primary'
                          onClick={event =>
                            handleToggleSelectAll(aggregatorName, event)
                          }
                          className={classes.selectButton}>
                          {selectedItems?.some(uuid =>
                            games?.some((game: any) => game.uuid === uuid),
                          )
                            ? 'Deselect All'
                            : 'Select All'}
                        </Button>
                      )}
                    </div>
                  }
                  key={aggregatorName}>
                  <div>
                    {games.length > 0 ? (
                      <GamesTable
                        data={games}
                        selecetdDataConfig={configWithCheckbox}
                      />
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Empty
                          image={<img src={img} />}
                          description='No Game Found'
                        />
                      </div>
                    )}
                  </div>
                </Panel>
              )),
            )}
          </Collapse>
          <Button
            type='primary'
            onClick={handleDeleteSelected}
            className={classes.customButton}>
            Sync
          </Button>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default AggregatorsAccordion;
