/* eslint-disable */
import { Modal, Table, Button, Popconfirm } from 'antd';
import { FC, useState, useEffect } from 'react';
import AddGamesToTag from './AddGamesToTag';
import { useDispatch, useSelector } from 'react-redux';
import {
  addGamesTagThunk,
  deleteGameFromTagThunk,
  getTagGamesThunk,
} from 'redux/reducers/tags/tags.thunks';
import { AppDispatch, RootState } from 'redux/store.types';
import {
  clearSearchGames,
  clearSelctedFullTgaInfo,
} from 'redux/reducers/tags/tags.slice';

import { GameData } from 'redux/reducers/tags/types';
import { columns } from './Tags.config';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';

interface GamesModalProps {
  isVisible: boolean;
  onCancel: () => void;
  onDeleteGame?: (gameId: string, tagId: string) => void;
}

const PAGE_SIZE = 20;

const GamesModal: FC<GamesModalProps> = ({ isVisible, onCancel }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedForAddition, setSelectedForAddition] = useState<string[]>([]);
  const { selectedTag, selectedFullTagInfo, loadingForModal, gameCount } =
    useSelector((state: RootState) => state.tags);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    console.log('SCROLL POSITION:', { scrollTop, scrollHeight, clientHeight });

    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

    console.log('SCROLL PERCENTAGE:', scrollPercentage);

    if (scrollPercentage >= 1 && !loading) {
      fetchData();
    }
  };

  useEffect(() => {
    selectedTag &&
      dispatch(getTagGamesThunk({ tagId: selectedTag.id, page: 1 }));
    dispatch(clearSearchGames());
  }, []);

  const handleDelete = (id: string | number) => {
    console.log('Deleting game with id:', id);
    dispatch(
      deleteGameFromTagThunk({ tagId: selectedTag?.id, gameId: id.toString() }),
    );
  };

  const handleSaveAll = () => {
    console.log('Games to add:', selectedForAddition);
    dispatch(
      addGamesTagThunk({
        tagId: selectedTag?.id,
        gameIds: selectedForAddition,
      }),
    );
    onCancel();
    dispatch(clearSelctedFullTgaInfo());
  };

  const newColumns = [
    ...columns,
    {
      title: 'Check for Delete',
      key: 'Check for Delete',
      render: (record: GameData) => (
        <Popconfirm
          title='Delete Game'
          description='Are you sure you want to remove this game from the tag?'
          onConfirm={() => handleDelete(record.id)}
          okText='Yes'
          cancelText='No'>
          <Button danger type='link'>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const onCancelModal = () => {
    onCancel();
    dispatch(clearSelctedFullTgaInfo());
  };

  const fetchData = async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      const nextPage = page + 1;

      if (selectedFullTagInfo.length >= gameCount) {
        setHasMore(false);
        return;
      }

      await dispatch(
        getTagGamesThunk({
          tagId: selectedTag?.id,
          page: nextPage,
        }),
      );

      const newData = selectedFullTagInfo || [];

     
      if (newData.length >= gameCount) {
        setHasMore(false);
      }

      if (newData.length > 0) {
        setPage(nextPage);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`Games in ${selectedTag?.name || ''} Tag`}
      open={isVisible}
      onCancel={onCancelModal}
      width={1000}
      footer={[
        <Button key='save' type='primary' onClick={handleSaveAll}>
          Save All Changes
        </Button>,
        <Button key='cancel' onClick={onCancelModal}>
          Cancel
        </Button>,
      ]}>
      <div
        onScroll={handleScroll}
        style={{ height: '400px', overflowY: 'auto' }}>
        <Table
          columns={newColumns}
          dataSource={selectedFullTagInfo}
          rowKey='id'
          pagination={false}
          scroll={{ y: 300 }}
          onScroll={e => handleScroll(e)}
        />
      </div>

      <AddGamesToTag
        tagId={selectedTag?.id || ''}
        onSelectedGamesChange={setSelectedForAddition}
        selectedFullTagInfo={selectedFullTagInfo}
      />
    </Modal>
  );
};

export default GamesModal;
